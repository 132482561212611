body {
  margin: 0;
  font-family: "NunitoMedium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "NunitoMedium"; /*Can be any text*/
  src: local("NunitoMedium"),
    url("../fonts/Nunito-Medium.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.quicksearch {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vertical-center {
  font-family: "NunitoMedium";
  min-height: 40%; /* Fallback for browsers do NOT support vh unit */
  min-height: 40vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.dialogicon {
  font-size: 80px !important;
}

.largeicon {
  font-size: 150px !important;
}

.smallsearchbutton {
  max-width: 60px !important;
  min-width: 60px !important;
}

.pointer {
  cursor: pointer;
}

.allcatholicdark {
  font-family: "NunitoMedium";
  color: #3c3636;
}

.maxinputwidth {
  max-width: 500px;
}

.maxnotificationwidth {
  max-width: 800px;
}

.gradientdarkbackground {
  background-image: linear-gradient(
    to bottom,
    #cfe2fc,
    #dfecfe,
    #dfecfe,
    #e1eeff,
    #e4efff,
    #e7f1ff
  );
}

.marianblue {
  color: #2a4694;
  font-family: "NunitoMedium";
}

.marianbutton {
  color: white !important;
  background-color: #2a4694 !important;
}

.allcatholicfont {
  font-family: "NunitoMedium";
}

.tallerheight {
  line-height: 1.7em;
  font-size: 1em;
}

.adjusttop {
  margin-top: -3px;
}

.gradientredbackground {
  background-image: linear-gradient(
    to bottom,
    #faacac,
    #fedfdf,
    #fee2df,
    #ffe3e1,
    #ffe4e4,
    #ffebe7
  );
}

.navshadow {
  background-color: #ffffff;
  border-bottom: 1px solid #2a4694;
  box-shadow: 1px 1px 8px #2a4694;
}

.dashedborder {
  border: 1px dashed #a0a0a0 !important;
  border-radius: 8px;
}

.appearslowly {
  animation: fadeIn 0.2s;
}

.forcetruncate {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.withtextdeco {
  text-decoration: underline !important;
}

.notextdeco {
  text-decoration: none !important;
}

.namecolor:hover {
  text-decoration: underline !important;
}

.namecolor {
  text-decoration: none !important;
}

.lightframeborder {
  border: 1px solid #dfe1e4;
  border-radius: 9px;
}

.lightframedashedborder {
  border: 1px dashed #dfe1e4 !important;
  border-radius: 8px;
}

div.wrapper {
  z-index: 1000 !important;
}

.ovalcornernocolor {
  border-radius: 20px !important;
}

.selectedcategory {
  border: 1px solid !important;
  font-size: 0.9em;
}

.notselectedcategory {
  border: 1px solid #cfe2ff !important;
  font-size: 0.9em;
}

.roundedframeborder {
  border-radius: 8px;
}

.roundborder {
  margin: auto;
  color: #2a4694;
  border: 1px solid #2a4694;
  border-radius: 50%;
  padding: 9px;
  width: 45px;
  height: 45px;
}

.roundbutton {
  margin: auto;
  color: #2a4694;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding: 9px;
  width: 45px;
  height: 45px;
}

.roundbutton:hover {
  margin: auto;
  color: #fff;
  background-color: #2a4694;
  border: 1px solid #2a4694;
  border-radius: 50%;
  padding: 9px;
  width: 45px;
  height: 45px;
}

.roundcornerbutton {
  margin: auto;
  color: #2a4694;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px;
  /* width: 45px;
  height: 45px; */
}

.roundcornerbutton:hover {
  margin: auto;
  color: #fff;
  background-color: #2a4694;
  border: 1px solid #2a4694;
  border-radius: 8px;
  padding: 8px;
  /* width: 45px;
  height: 45px; */
}

.roundcornerlightbutton {
  margin: auto;
  color: #707070;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 8px;
}

.roundcornerlightbutton:hover {
  margin: auto;
  color: #2a4694;
  /* background-color: #606060; */
  border: 1px solid #2a4694;
  border-radius: 8px;
  padding: 8px;
}

.blinking-cursor::after {
  content: "|";
  animation: blink 1s step-end infinite;
}
@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.seemore,
.seemore:hover {
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  color: #888;
  /* font-weight: 600; */
  /* float: right; */
}

.ais-SearchBox-input {
  border-radius: 8px !important;
  height: 50px !important;
  display: flex;
  align-items: center !important;
  font-size: 1.3em !important;
  /* 
  padding-top: 5px !important;
  padding-bottom: 5px !important; */
}
.ais-SearchBox-form {
  height: 50px !important;
  display: flex;
  align-items: center !important;
}

.clear-icon {
  margin-top: -3px !important;
}

.clamp3 {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3 !important; /* number of lines to show */
  line-clamp: 3 !important;
  -webkit-box-orient: vertical;
}
